/*
 * @Description:
 * @Autor: 张健
 * @Date: 2022-07-09 10:33:16
 * @LastEditors: jiangchao
 * @LastEditTime: 2023-10-16 12:59:13
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import * as common from '@/utils/common';
import { getData } from '@/api/index'
import { VueJsonp } from 'vue-jsonp'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(VueJsonp)
// import VConsole from 'vconsole';
Vue.config.productionTip = false;
Vue.prototype.$common = common;
Vue.prototype.$getData = getData;
Vue.prototype.$KEY = 'GD2BZ-2TM3X-RMV4U-TSAL2-WNS2K-VGFAA';

// Vue.use(MapboxVue, { mapboxgl: Mapbox })
Vue.use(Antd)
// const vConsole = new VConsole();
// Vue.use(vConsole)
// Vue.prototype.$qqMaps = qmap

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
