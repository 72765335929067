/*
 * @Description:
 * @Autor: 张健
 * @Date: 2022-07-09 10:33:16
 * @LastEditors: jiangchao
 * @LastEditTime: 2023-10-16 12:58:50
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";

Vue.use(VueRouter);

VueRouter.prototype.push = function push(location, onComplete, onAbort) {
    var this$1 = this;
    // $flow-disable-line
    if (!onComplete && !onAbort && typeof Promise !== "undefined") {
        return new Promise(function (resolve, reject) {
            this$1.history.push(location, resolve, reject);
        });
    } else {
        this.history.push(location, onComplete, onAbort);
    }
};

const routes = [
    {
        path: "/",
        redirect: "/wendu/index",
        component: Layout,
        children: [
            {
                path: "/wendu/index",
                name: "文都",
                component: () => import("@/views/wenxueditu/index.vue"),
                meta: {
                    showNav: true,
                },
            }
        ],
    },
];

const router = new VueRouter({
    mode: "hash",
    routes,
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        };
    },
});

function loadJS1(url) {
    return new Promise((resolve) => {
        let script = document.createElement("script");
        script.src = url;
        // IE
        if (script.readyState) {
            script.onreadystatechange = () => {
                if (script.readyState == "loaded" || script.readyState == "complete") {
                    script.onreadystatechange = null;
                    resolve();
                }
            };
        } else {
            // 其他浏览器
            script.onload = function () {
                resolve();
            };
        }
        document.getElementsByTagName("head")[0].appendChild(script);
    });
}
function loadJS(url) {
    return new Promise((resolve) => {
        let script = document.createElement("script");
        script.src = url;
        script.type = "text/javascript";
        // IE
        if (script.readyState) {
            script.onreadystatechange = () => {
                if (script.readyState == "loaded" || script.readyState == "complete") {
                    script.onreadystatechange = null;
                    resolve();
                }
            };
        } else {
            // 其他浏览器
            script.onload = function () {
                resolve();
            };
        }
        document.getElementsByTagName("head")[0].appendChild(script);
    });
}

function loadJsAll() {
    return Promise.all([
        loadJS(
            "https://map.qq.com/api/gljs?v=2.exp&key=2VSBZ-ZI43W-FJBRU-3P5EA-QUSOQ-32BIA"
        ),
        loadJS1(
            "https://mapapi.qq.com/web/mapComponents/geoLocation/v/geolocation.min.js"
        ),
    ]);
}

router.beforeEach((to, from, next) => {
    if (to.path ==="/wenxueta/dangdai") {
        loadJsAll().then(() => {
            next();
        });
    } else {
        next();
    }
});
router.load = ()=>{
    loadJsAll().then(()=>{
    });
};


export default router;
