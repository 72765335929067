<!--
 * @Description: 
 * @Autor: 张健
 * @Date: 2022-07-09 10:33:16
 * @LastEditors: 张健
 * @LastEditTime: 2022-09-06 13:53:37
-->
<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    name: "App",
};
</script>
<style lang="scss">
* {
    box-sizing: border-box;
}
img {
    pointer-events: none !important;
}
.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ellipsis2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>
