/*
 * @Description:
 * @Autor: 张健
 * @Date: 2022-08-09 11:19:54
 * @LastEditors: jiangchao
 * @LastEditTime: 2023-09-19 16:07:38
 */
import request from '@/utils/request';
import {jsonp} from "vue-jsonp";

export function getData(action, params = {}) {
  Object.assign(params, {
    action
  });
  // return request({
  //   url: 'https://tmwd-test.nuoqu.net/tools/app.ashx',
  //   method: 'get',
  //   params
  // });
  return request({
    url: 'https://tmwd.nuoqu.net/tools/app.ashx',
    method: 'get',
    params
  });
}
export function getWork(){
  return request({
    url: '/ajax/data_api/all/works',
    method: 'get',
  });
}

// export function userShowcang(){
//   return request({
//     url:'',
//     method:'post'
//   })
// }

